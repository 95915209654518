<template>
    <v-app>
        <v-main>
            <template v-if="notBase.includes($route.name)">
                <router-view />
            </template>
            <template v-if="!notBase.includes($route.name)">
                <AdminLayout>
                    <router-view />
                </AdminLayout>
            </template>
            <Confirm ref="confirm"></Confirm>
            <Vue3Snackbar dense bottom :duration="4000"></Vue3Snackbar>
        </v-main>
        <Confirm ref="confirm"></Confirm>
    </v-app>
</template>

<script setup>


</script>

<script>
import Confirm from "./components/Confirm.vue";
import AdminLayout from "./layouts/Admin/default";
import {Vue3Snackbar} from "vue3-snackbar";
export default {
    name: "App",

    components: {
        AdminLayout,
        Vue3Snackbar,
        Confirm
    },
    setup() {

    },
    data: () => ({
        notBase: ["Home", "Login", "ForgottenPassword", "ForgottenPasswordCode"],
    }),
    methods: {},
    watch: {
        $route() {

        }
    },
    mounted() {
        this.$root.confirm = this.$refs.confirm;
    }
};
</script>
<style>
#app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
</style>

