<template>
    <v-dialog v-model="dialog"
              persistent
              width="auto"
              @keydown.esc="cancel">
        <v-card>
            <v-card-title class="text-h5" :color="options.color">
                {{ title }}
            </v-card-title>
            <v-card-text v-show="!!message" class="pa-4">{{ message }}</v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="agree">Ano</v-btn>
                <v-btn color="grey" text @click="cancel">Ne</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            resolve: null,
            reject: null,
            message: null,
            title: null,
            options: {
                color: 'primary',
                width: 290,
                zIndex: 200
            }
        };
    },
    methods: {
        open(title, message, options = {}) {
            this.dialog = true;
            this.title = title;
            this.message = message;
            this.options = Object.assign(this.options, options);
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        agree() {
            if (this.resolve) {
                this.resolve(true);
            }
            this.closeDialog();
        },
        cancel() {
            if (this.resolve) {
                this.resolve(false);
            }
            this.closeDialog();
        },
        closeDialog() {
            this.dialog = false;
            this.resolve = null;
            this.reject = null;
            this.message = null;
            this.title = null;
            this.options = {
                color: 'primary',
                width: 290,
                zIndex: 200
            };
        }
    }
};
</script>
