<script>
import User from "../services/UserService";
import Spinner from "../components/Spinner.vue";
export default {
    name: "Login",
    components: {
        Spinner
    },
    created() {
        document.title = "West Haven RP - Přihlášení";
    },
    data: () => ({
        valid: false,
        name: '',
        password: null,
        processing: false,
        passwordRules: [
            value => {
                if (value) return true
                return "You must fill in a password."
            }
        ]
    }),
    methods: {
        login() {
            this.processing = true;
            return User.login(this.name, this.password)
                .then(res => {
                    this.processing = false;
                    this.$router
                        .push({ name: "Dashboard" })
                        .catch(() => {});
                    this.$snackbar.add({
                        type: "success",
                        title: "Přihlášení",
                        text: "Nepodařilo se vás přihlásit",
                        dismissible: true
                    })
                })
                .catch(err => {
                    this.$snackbar.add({
                        type: "error",
                        title: "Přihlášení",
                        text: "Nepodařilo se vás přihlásit",
                        dismissible: true
                    })
                    this.processing = false;
                    this.name = "";
                    this.password = "";
                });
        },
    }
}
</script>

<template>
    <v-app id="inspire" >
        <v-main>
            <v-container  class="d-flex align-center justify-center" style="height: 100vh" fluid>
                <v-sheet width="400" class="mx-auto">
                    <v-card>
                        <div class="form-top-logo-wrapper mt-5 d-flex flex-column align-center justify-center">
                            <img src="/images/westhaven.png" width="98">
                            <br />
                          <span class="mb-5">West Haven RP RP - Administrace</span>
                        </div>
                        <v-card-text>
                            <Spinner v-if="processing" />
                            <v-form fast-fail @submit.prevent="login" v-model="valid">
                                <v-text-field
                                    v-model="name"
                                    label="Login"
                                    required
                                ></v-text-field>

                                <v-text-field
                                    v-model="password"
                                    label="Heslo"
                                    type="password"
                                    :rules="passwordRules"
                                    required
                                ></v-text-field>

                                <v-btn type="submit" block class="mt-2">Přihlásit se</v-btn>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-sheet>
            </v-container>
        </v-main>
    </v-app>
</template>

<style scoped>

</style>
