const obj = {
  //apiUrl: "https://api.westhavenrp.cz/api/v1",
    apiUrl: "https://api.westhavenrp.cz/api/v1",
  headers: (auth = false) => {
    const token = localStorage.getItem("token");
    if (auth) {
      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
      };
    } else {
      return {
        "Content-Type": "application/json",
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
      };
    }
  }
};

export default obj;
